import { Link } from "gatsby"
import React from "react"

export default () => {
  return (
    <div className="notFoundPage">
      <h1>404 - Not found</h1>
      <Link to="/">Try out homepage instead</Link>
    </div>
  )
}
